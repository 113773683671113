import { Injectable } from '@angular/core';
import { GetRegistrantsViewItem } from 'edu-application/dist/registration/view-registrants/view-registrants.viewmodel';
import { ViewStudentsViewItem } from 'edu-application/dist/students/view-students/view-students.viewmodel';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  constructor() { }

  getVCF(contacts: ViewStudentsViewItem[]): string {
    let vcards = [];
    for (let contact of contacts) {
      let vcf = `BEGIN:VCARD
VERSION:3.0
N:;P. ${contact.fullName} ${contact.userName};;;
FN:P. ${contact.fullName} ${contact.userName}
EMAIL;type=INTERNET;type=WORK:${contact.email}
TITLE:${contact.userName}
item1.TEL:${contact.parentPhoneNumber}
item1.X-ABLabel:Parent
END:VCARD
BEGIN:VCARD
VERSION:3.0
N:;${contact.fullName} ${contact.userName};;;
FN:${contact.fullName} ${contact.userName}
EMAIL;type=INTERNET;type=WORK:${contact.email}
TITLE:${contact.userName}
item1.TEL:${contact.phoneNumber}
item1.X-ABLabel:Student
END:VCARD`;
      vcards.push(vcf);
    }
    return vcards.join('\n');
  }

  getRegistrantVCF(contacts: GetRegistrantsViewItem[]): string {
    let vcards = [];
    for (let contact of contacts) {
      let vcf = `BEGIN:VCARD
VERSION:3.0
N:;P. ${contact.fullName} ${contact.session} ${contact.place};;;
FN:P. ${contact.fullName} ${contact.session} ${contact.place}
EMAIL;type=INTERNET;type=WORK:${contact.email}
TITLE:${contact.fullName}
item1.TEL:${contact.parentPhoneNumber}
item1.X-ABLabel:Parent
END:VCARD
BEGIN:VCARD
VERSION:3.0
N:;${contact.fullName} ${contact.session} ${contact.place};;;
FN:${contact.fullName} ${contact.session} ${contact.place}
EMAIL;type=INTERNET;type=WORK:${contact.email}
TITLE:${contact.fullName}
item1.TEL:${contact.phoneNumber}
item1.X-ABLabel:Student
END:VCARD`;
      vcards.push(vcf);
    }
    return vcards.join('\n');
  }
}
