import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Form, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { differentValueValidator, equalValueValidator } from './validators/matching-value.validator';
import { RegistrationError } from './registration.error';
import { RegistrationStep } from './registration.step';
import { countryList } from "./allowed-countries";
import { RegistrationService } from '../registration.service';
import { StudentRegistrationRequest } from 'edu-application/dist/registration/student-registration/student-registration.request';
import { GetPlacesViewItem } from 'edu-application/dist';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate("300ms 300ms ease-in-out", style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(300, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class RegisterComponent implements OnInit {
  logoSrc = environment.assets.logo;
  filteredOptions: Observable<String[]>;
  environment = environment;
  fullNameController: FormControl = new FormControl('', [Validators.required, Validators.pattern(/[a-zA-Z]{2,}\s{1}[a-zA-Z]{2,}\s{1}[a-zA-Z]{2,}\s{1}[a-zA-Z]{2,}/)])
  genderController: FormControl = new FormControl('', [Validators.required]);
  emailController: FormControl = new FormControl('', [Validators.required, Validators.email]);
  emailConfirmationController: FormControl = new FormControl('', [Validators.required, Validators.email])
  phoneNumberController: FormControl = new FormControl('', [Validators.required]);
  parentPhoneNumberController: FormControl = new FormControl('', [Validators.required]);
  groupTypeController: FormControl = new FormControl('', []);
  schoolController: FormControl = new FormControl('', []);
  groupController: FormControl = new FormControl('', []);
  countryController: FormControl = new FormControl('', []);

  registrationLoading: boolean = true;
  registrationSuccess: boolean = false;
  registrationClosed: boolean = true;
  registrationError: RegistrationError = null;

  registrationGroup = new FormGroup({
    fullName: this.fullNameController,
    email: this.emailController,
    confirmEmail: this.emailConfirmationController,
    phoneNumber: this.phoneNumberController,
    parentPhoneNumber: this.parentPhoneNumberController,
    groupType: this.groupTypeController,
    group: this.groupController,
    school: this.schoolController,
    country: this.countryController,
    gender: this.genderController
  }, [equalValueValidator('email', 'confirmEmail'), differentValueValidator('phoneNumber', 'parentPhoneNumber')]);

  currentStep = 0;
  allSteps: RegistrationStep[] = [
    {
      step: 0,
      title: 'Personal Details',
      includedKeys: [
        'fullName',
        'email',
        'confrimEmail',
        'phoneNumber',
        'parentPhoneNumber',
        'gender'
      ]
    },
    {
      step: 1,
      title: 'Registration Details',
      includedKeys: [
        'groupType',
        'group',
        'schoolName'
      ]
    },
    {
      step: 2,
      title: 'Successful registration',
      includedKeys: []
    }
  ];
  nextStep() {
    if (this.registrationGroup.valid) {
      this.currentStep += 1;
      this.setValidators();
      if(this.isEshra7ly) {
        this.groupTypeController.setValue('private');
        this.radioButtonChange();
      }
    }
  }
  back() {
    this.currentStep -= 1;
    this.setValidators();
  }

  setValidators() {
    if (this.currentStep == 1) {
      this.registrationGroup.setControl('group', this.groupController);
      this.registrationGroup.setControl('school', this.schoolController);
      this.registrationGroup.setControl('groupType', this.groupTypeController);
      this.registrationGroup.setControl('country', this.countryController);
      this.schoolController.setValidators(Validators.required);
      this.groupTypeController.setValidators(Validators.required);
    }
    if (this.currentStep == 0) {
      this.schoolController.clearValidators();
      this.groupController.clearValidators();
      this.countryController.clearValidators();
      this.groupTypeController.clearValidators();
      this.registrationGroup.removeControl('school')
      this.registrationGroup.removeControl('group');
      this.registrationGroup.removeControl('groupType');
      this.registrationGroup.removeControl('country');
    }
  }

  radioButtonChange() {
    if (this.groupTypeController.value == 'private') {
      this.chosenPlace = null
      this.groupController.setValidators(Validators.required);
      this.schoolController.reset();
      this.groupController.enable();
    } else {
      this.chosenPlace = null
      this.groupController.reset();
      this.groupController.clearValidators();
      this.groupController.disable();
      this.countryController.setValue('Egypt');
    }
  }
  chosenPlace: GetPlacesViewItem;
  groupChanged() {
    if (this.groupTypeController.value == "school") {
      this.registrationGroup.controls["group"].setValue(
        this.registrationGroup.controls["school"].value
      );
      this.chosenPlace = this.schoolPlaces.find(s => s.placeName == this.groupController.value);
    } else {
      this.chosenPlace = this.privatePlaces.find(s => s.placeName == this.groupController.value);
    }
    if (this.groupController.value.name == "Online") {
      this.countryController.setValidators(Validators.required)
      this.countryController.reset();
    } else {
      this.countryController.reset();
      this.countryController.clearValidators();
      this.countryController.setValue('Egypt')
    }
  }
  studentsLink: string;
  parentsLink: string;
  register() {
    if (this.groupTypeController.value == "school") {
      this.registrationGroup.controls["group"].setValue(
        this.registrationGroup.controls["school"].value
      );
    }
    if (this.registrationGroup.invalid) {
      return;
    }

    let registrantObject: StudentRegistrationRequest = {
      fullName: this.registrationGroup.controls["fullName"].value as string,
      email: this.registrationGroup.controls["email"].value as string,
      phoneNumber: this.registrationGroup.controls["phoneNumber"].value as string,
      parentPhoneNumber: this.registrationGroup.controls["parentPhoneNumber"].value as string,
      group: this.registrationGroup.controls["group"].value as string,
      groupType: this.registrationGroup.controls["groupType"].value as string,
      schoolName: this.registrationGroup.controls["school"].value as string,
      isEshra7ly: false,
      gender: this.registrationGroup.controls["gender"].value as string
    };
    this.registrationLoading = true;
    this._registrationService.register(registrantObject).then(s => {
      this.registrationSuccess = true;
      this.currentStep += 1;
      if (s.studentsLink) {
        this.studentsLink = s.studentsLink;
      }
      if (s.parentsLink) {
        this.parentsLink = s.parentsLink;
      }
    }).catch(e => {
      let message = e.error.message.toLowerCase();
      if (message.includes('exists')) {
        this.back();
        setTimeout(() => {
          this.phoneNumberController.setErrors({
            duplicate: {
              message: 'phone number'
            }
          });
        }, 300);
        setTimeout(() => {
          this.emailController.setErrors({
            duplicate: {
              message: 'email'
            }
          })
        }, 1000);

      }
    }).finally(() => {
      this.registrationLoading = false;
    });
  }
  constructor(
    private _registrationService: RegistrationService,
    private _currentRoute: ActivatedRoute
  ) { }
  isEshra7ly: boolean = false;
  ngOnInit() {
    let place = this._currentRoute.snapshot.queryParams["place"];
    if (place && place.toLowerCase() === "eshra7ly") {
      this.isEshra7ly = true;
    }
    this.filteredOptions = this.countryController.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : countryList.slice())
      );
    this._getPlaces();
  }
  private _filter(name: string): String[] {
    const filterValue = name.toLowerCase();
    return countryList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  schoolPlaces: GetPlacesViewItem[];
  privatePlaces: GetPlacesViewItem[];
  newGroupPlaces: GetPlacesViewItem[];
  private _getPlaces() {
    this._registrationService.getPlaces().then(s => {
      this.schoolPlaces = s.filter(s => s.placeType == 'school' && s.availableForRegistration == true);
      this.privatePlaces = s.filter(s => s.placeType == 'private' && s.availableForRegistration == true);
      if (this.isEshra7ly) {
        this.privatePlaces = this.privatePlaces.filter(s => s.placeName.toLowerCase().includes('eshra7ly'));
        console.log(this.privatePlaces);
      }
      this.newGroupPlaces = s.filter(s => s.availableForNewGroup == true);
      if (this.schoolPlaces.length != 0 || this.privatePlaces.length != 0) {
        this.registrationClosed = false;
      }
      this.registrationLoading = false;
    })
  }

  getNewGroupGroups() {
    if (this.chosenPlace.placeName.toLowerCase().includes('eshra7ly')) {
      return this.newGroupPlaces.filter(s => s.placeName.toLowerCase().includes('eshra7ly'));
    } else {
      return this.newGroupPlaces.filter(s => !s.placeName.toLowerCase().includes('eshra7ly'));
    }
  }
}
