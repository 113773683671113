<loading-spinner class="form" *ngIf="this.isLoading"></loading-spinner>
<div class="form light-form" *ngIf="!this.isLoading">
    <p class="icteam-form-field">Type your username and password to login to the system</p>
    <mat-form-field class="icteam-form-field" appearance="outline">
        <input matInput type="text" [formControl]='this.userNameController'/>
        <mat-icon matSuffix>person</mat-icon>
        <mat-label>Username</mat-label>
        <mat-hint>Write your username</mat-hint>
        <mat-error *ngIf="this.userNameController.hasError('required')">
            Username is <strong>required</strong>
        </mat-error>
    </mat-form-field>
    <br/>
    <mat-form-field class="icteam-form-field" appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput type="password" [formControl]='this.passwordController' (keyup)="handleKeyUp($event)"/>
        <mat-icon matSuffix>password</mat-icon>
        <mat-hint>Type your password</mat-hint>
        <mat-error *ngIf="this.passwordController.hasError('required')">
            Password is <strong>required</strong>
        </mat-error>
    </mat-form-field>
    <br>
    <button color="red" class="icteam-form-field" (click)="this.login()" mat-raised-button>Login</button>
</div>
