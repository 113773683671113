import { Injectable, NgZone } from '@angular/core';
import { AccessTokenErrorResponse, AccessTokenResponse, AccessTokenSuccessResponse } from 'edu-auth/dist/responses/access-token.response';
import { PasswordGrantTokenRequest } from 'edu-auth/dist/request/access-token.request';
import { UserInfoResponse } from 'edu-auth/dist/responses/user-info-response';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service'
import { HttpClient } from '@angular/common/http';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket'
import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private readonly TOKEN_KEY = "access-token";
  private readonly REFRESH_TOKEN_KEY = "refresh-token";
  private readonly USER_INFO_KEY = "user-info";
  private readonly SCOPES_KEY = "scope"

  constructor(private _zone: NgZone, private _cookieService: CookieService, private _client: HttpClient) { }
  public get isLoggedIn(): boolean {
    let cookieToken = this._cookieService.get(this.TOKEN_KEY)
    return cookieToken != null && cookieToken != undefined && cookieToken.length != 0;
  }

  public get hasInfo(): boolean {
    let info = this._cookieService.get(this.USER_INFO_KEY);
    return info != null && info != undefined && info.length != 0;
  }

  public get access_token(): string {
    let token = this._cookieService.get(this.TOKEN_KEY);
    if (token == undefined || token == null || token.length == 0) {
      throw new Error('User is not logged in');
    }
    return token
  }

  public get isAdmin(): boolean {
    let info = this.userInfo;
    return info.type != 'student';
  }

  public get refresh_token(): string | undefined {
    let token = this._cookieService.get(this.REFRESH_TOKEN_KEY);
    if (token == undefined || token == null || token.length == 0) {
      return undefined;
    }
    return token;
  }

  public get scopes(): string[] {
    return this._cookieService.get(this.SCOPES_KEY)?.split(' ');
  }

  public async getUserInfo(): Promise<UserInfoResponse> {
    let oldInfo = this._cookieService.get(this.USER_INFO_KEY);
    if (oldInfo != undefined && oldInfo != null && oldInfo.length != 0) {
      return JSON.parse(oldInfo);
    }
    let info = await this._client.get<UserInfoResponse>(`${environment.backend_url}/auth/user-info`).toPromise();
    try {
      this._cookieService.set(this.USER_INFO_KEY, JSON.stringify(info), { secure: environment.production, path: '/' });
      return info;
    } catch(e) {
      console.log(e);
      throw e;
    }
  }

  public get userInfo(): UserInfoResponse {
    let info = this._cookieService.get(this.USER_INFO_KEY);
    if (info == undefined || info == null || info.length == 0) {
      throw new Error('User is not logged in');
    }
    return JSON.parse(info);
  }
  public logout(): void {
    this._cookieService.deleteAll('/');
  }

  public async login(tokenRequest: PasswordGrantTokenRequest): Promise<AccessTokenResponse> {
    try {
      this._cookieService.deleteAll('/')
      let response = await this._client.post<AccessTokenResponse>(`${environment.backend_url}/auth/token`, tokenRequest).toPromise();
      this.setTokens(response);
      return response;
    } catch (e) {
      throw new Error(e.error.message);
    }
  }

  setTokens(response: AccessTokenResponse) {
    let success_response = response as AccessTokenSuccessResponse
    if (success_response.access_token) {
      this._cookieService.set(this.TOKEN_KEY, success_response.access_token, { secure: environment.production, path: '/' });
      if (success_response.refresh_token != undefined) {
        this._cookieService.set(this.REFRESH_TOKEN_KEY, success_response.refresh_token, { secure: environment.production, path: '/' });
      }
      this._cookieService.set(this.SCOPES_KEY, success_response.scope, { secure: environment.production, path: '/' });
    } else {
      throw new Error(`Error occurred ${(response as AccessTokenErrorResponse).error_description}`);
    }
  }

  async checkDeviceId(deviceId: string): Promise<boolean> {
    try {
      let info = await this._client.get<any>(`${environment.backend_url}/auth/device-info/${deviceId}`).toPromise();
      return info.state;
    } catch (e) {
      return false;
    }
  }

  public async refreshToken(newToken: string) {
    this._cookieService.set(this.TOKEN_KEY, newToken);
  }
}
