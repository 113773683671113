import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginService } from '../login.service';
import { AccessTokenResponse, AccessTokenSuccessResponse, AccessTokenErrorResponse } from 'edu-auth/dist/responses/access-token.response';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isLoading: boolean = false;

  loginStream: BehaviorSubject<boolean> = new BehaviorSubject(null);
  deviceId: string;
  environment = environment;
  isNavigating: boolean = false;
  constructor(
    private _loginService: LoginService,
    private _router: Router,
    private activatedRoute: ActivatedRoute
  ) { }
  subscription: Subscription;
  subscribeLogin() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.loginStream.subscribe(async value => {
      if (value == null || value == false) {
        return;
      }
      this.isNavigating = true;
      await this.getUserInfo();
    }, (error: any) => {
    })
  }
  redirectUrl: string;
  ngOnInit(): void {
    if (this.activatedRoute.snapshot.queryParamMap.has('redirect_to')) {
      this.redirectUrl = this.activatedRoute.snapshot.queryParamMap.get('redirect_to');
    }
    if (this.activatedRoute.snapshot.queryParamMap.has('access_token')) {
      this._loginService.setTokens({ access_token: this.activatedRoute.snapshot.queryParamMap.get('access_token'), scope: 'web', token_type: 'access_token', expires_in: 3600 });
      this.getUserInfo();
      return;
    }
    if (this._loginService.isLoggedIn) {
      if (!this._loginService.hasInfo) {
        this.isNavigating = true;
        this.getUserInfo();
      } else {
        this.redirect();
      }
    } else {
      this.subscribeLogin();
    }
  }

  private redirect() {
    if (this.redirectUrl != null) {
      this._router.navigateByUrl(this.redirectUrl);
    } else {
      if (this._loginService.userInfo.type == 'team' && this._loginService.userInfo.teamMemberInfo.type !== 'quality') {
        this._router.navigate(['/portal/dashboard']);
      } else {
        this._router.navigate(['/portal/home'])
      }
    }
  }
  async getUserInfo() {
    try {
      this.isLoading = true;
      await this._loginService.getUserInfo();
      this.redirect();
    } catch (e) {

    } finally {
      this.isLoading = false;
      this.isNavigating = false;
    }
  }

}
