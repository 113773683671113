import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoginService } from '../login.service';
import { PasswordGrantTokenRequest } from 'edu-auth/dist/request/access-token.request';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-form-login',
  templateUrl: './form-login.component.html',
  styleUrls: ['./form-login.component.scss']
})
export class FormLoginComponent implements OnInit {
  @Input('loginStream') loginStream: BehaviorSubject<boolean>;
  userNameController: FormControl = new FormControl('', [Validators.required]);
  passwordController: FormControl = new FormControl('', [Validators.required]);
  constructor(
    private _service: LoginService,
    private _snackBar: MatSnackBar
  ) { }

  isLoading: boolean = false;
  ngOnInit(): void {

  }

  handleKeyUp(e:any){
    if(e.keyCode === 13){
       this.login();
    }
  }


  async login() {
    if (this.passwordController.invalid || this.userNameController.invalid) {
      return;
    }
    this.isLoading = true;
    try {
      let repsonse = await this._service.login({
        username: (this.userNameController.value as string).trim(),
        password: this.passwordController.value.trim(),
        grant_type: 'password',
        scope: 'angular'
      });
      this.loginStream.next(true);
    }
    catch (e) {
      this._snackBar.open(e.message, "dismiss", { duration: 3000, verticalPosition: "top", horizontalPosition: "right" })
    } finally {
      this.isLoading = false;
    }
  }
}
