<div class="student-container">
    <student-details [student]="student"></student-details>
    <div class="student-actions">
        <p class="student-name">Actions</p>
        <br />
        <button (click)="this.openTransferStudentDialog(this.student)" color="white" mat-stroked-button>
            <mat-icon>groups</mat-icon>
            Transfer
        </button>
        <br />
        <br />
        <button (click)="this.openSuspensionDialog(this.student)" mat-stroked-button color="white"
            *ngIf="!this.student.isSuspended">
            <mat-icon>block</mat-icon>
            Suspend
        </button>
        <button (click)="this.activateAccount()" [disabled]="this.isLoading" mat-stroked-button color="white"
            *ngIf="this.student.isSuspended">
            <mat-icon>refresh</mat-icon>Activate
        </button>
    </div>
    <div class="student-actions">
      <br />
      <br />
      <button (click)="this.resetDevices()" [disabled]="this.isLoading" mat-stroked-button color="white"
          *ngIf="this.student.devices && this.student.devices.length != 0">
          <mat-icon>lock_reset</mat-icon>Reset Devices
      </button>
      <br *ngIf="this.student.devices && this.student.devices.length != 0"/>
      <br *ngIf="this.student.devices && this.student.devices.length != 0" />
      <button (click)="this.resetPassword()" [disabled]="this.isLoading" mat-stroked-button color="white"
          *ngIf="!this.student.isSuspended">
          <mat-icon>password</mat-icon>Reset Password
      </button>
  </div>
</div>
