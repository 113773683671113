<div class="registration-container">
    <div class="left-part">
        <img [src]="environment.assets.logo" class="logo-pic" />
        <h5>{{environment.org}}</h5>
        <!-- <img src="assets/registration/home-illustration.svg" width="90%" /> -->
        <canvas class="animation-canvas" riv="floating-man" width="1000" height="1000">
            <riv-animation play mode="loop" name="float"></riv-animation>
        </canvas>
    </div>
    <div class="right-part">

        <div class="mobile-section">
            <img [src]="environment.assets.logo" class="logo-pic" />
            <h5>{{environment.org}}</h5>
        </div>
        <h5 *ngIf="!this.registrationSuccess && !this.registrationClosed">Join us</h5>
        <h1 *ngIf="!this.registrationSuccess && !this.registrationClosed">Register with {{environment.org}}</h1>
        <h3 *ngIf="!this.registrationSuccess && !this.registrationClosed">{{allSteps[currentStep].title}}</h3>
        <h1 *ngIf="this.registrationSuccess">Successful registration</h1>
        <div class="loading-container" *ngIf="registrationLoading">
            <!-- <app-book-loading></app-book-loading> -->
            <p>Processing your registration...</p>
        </div>
        <div class="loading-container"
            *ngIf="this.registrationSuccess && !this.registrationLoading && !this.registrationClosed">
            <img src="../../../assets/registration/launch.svg" width="75%" />
            <p>You have successfully registered with {{environment.org}}, your joureny has just begun</p>
            <p>We have sent you a confirmation email,
                <br>{{this.studentsLink == undefined && this.parentsLink == undefined ? 'Wait for the WhatsApp groups to
                be created' :'Join the WhatsApp group from the links or scan the QR Codes below to join'}}
            </p>
            <app-whatsapp-link *ngIf="this.studentsLink" title="Students"
                [link]="this.studentsLink"></app-whatsapp-link>
            <app-whatsapp-link *ngIf="this.parentsLink" title="Parents" [link]="this.parentsLink"></app-whatsapp-link>
        </div>
        <div class="loading-container" *ngIf="!this.registrationLoading && this.registrationClosed">
            <!-- <img src="../../../assets/registration/launch.svg" width="50%" /> -->
            <p>Unfortunately, registration with {{environment.org}} is currently closed</p>
            <p>Please contact one of our team members for more information
            <p>
        </div>
        <form
            *ngIf="!registrationLoading && !registrationSuccess && registrationError == null && !this.registrationClosed"
            class="dark-red-theme" [formGroup]='this.registrationGroup'>
            <div *ngIf="currentStep == 0" [@fade]>
                <mat-form-field class="icteam-form-field" appearance="outline">
                    <input matInput type="text" formControlName="fullName" />
                    <mat-icon matSuffix>badge</mat-icon>
                    <mat-label>Full Name</mat-label>
                    <mat-hint>Write at least 4 names</mat-hint>
                    <mat-error *ngIf="this.fullNameController.hasError('required')">
                        Full name is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="this.fullNameController.hasError('pattern')">
                        Full name must have at least 4 names
                    </mat-error>
                </mat-form-field>
                <mat-radio-group class="icteam-form-field gender-container radio-buttons-container"
                    [formControl]='this.genderController' (change)='this.radioButtonChange()'>
                    <mat-radio-button class="gender-button" value="male">
                        <ngx-image-button [selected]='this.genderController.value == "male"'
                            src="assets/registration/male_stroked.png" hoverImage="assets/registration/male_stroked.png"
                            selectedImage="assets/registration/male.png"></ngx-image-button>
                        <p>Male</p>
                    </mat-radio-button>
                    <mat-radio-button class="gender-button" value="female">
                        <ngx-image-button [selected]='this.genderController.value =="female"'
                            src="assets/registration/female_stroked.png"
                            hoverImage="assets/registration/female_stroked.png"
                            selectedImage="assets/registration/female.png"></ngx-image-button>
                        <p>Female</p>
                    </mat-radio-button>
                </mat-radio-group>

                <mat-form-field class="icteam-form-field" appearance="outline">
                    <input matInput type="email" [formControl]='this.emailController' />
                    <mat-icon matSuffix>alternate_email</mat-icon>
                    <mat-label>Email</mat-label>
                    <mat-hint>Write an email that you have access to</mat-hint>
                    <mat-error *ngIf="this.emailController.hasError('required')">
                        Email is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="this.emailController.hasError('email')">
                        {{this.emailController.value}} is not a valid email
                    </mat-error>
                    <mat-error *ngIf="this.emailController.hasError('mismatch')">
                        Must match the value of confirmation
                    </mat-error>
                    <mat-error *ngIf="this.emailController.hasError('duplicate')">
                        Email {{this.emailController.value}} is already registered
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="icteam-form-field" appearance="outline">
                    <mat-label>Email Confirmation</mat-label>

                    <input matInput type="email" [formControl]='this.emailConfirmationController' />
                    <mat-icon matSuffix>alternate_email</mat-icon>
                    <mat-hint>Confirm your email</mat-hint>
                    <mat-error *ngIf="this.emailConfirmationController.hasError('required')">
                        Email is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="this.emailConfirmationController.hasError('email')">
                        {{this.emailConfirmationController.value}} is not a valid email
                    </mat-error>
                    <mat-error *ngIf="this.emailConfirmationController.hasError('mismatch')">
                        Must match the value of the email
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="icteam-form-field" appearance="outline">
                    <ngx-mat-intl-tel-input [formControl]='this.phoneNumberController'
                        [onlyCountries]="['eg', 'sa', 'ae', 'qa', 'kw', 'lb', 'ly', 'om', 'dz', 'tn', 'bn', 'es', 'de', 'bh', 'gb', 'tr', 'ke', 'sd', 'rw']"
                        [enablePlaceholder]="true" [enableSearch]="true" name="phone">
                    </ngx-mat-intl-tel-input>
                    <mat-label>Student Phone Number</mat-label>
                    <mat-hint>A WhatsApp enabled phone number</mat-hint>
                    <mat-icon matSuffix>phone</mat-icon>
                    <mat-error *ngIf="this.registrationGroup.controls['phoneNumber']?.hasError('required')">Phone Number
                        is <strong>required</strong></mat-error>
                    <mat-error *ngIf="this.registrationGroup.controls['phoneNumber']?.hasError('validatePhoneNumber')">
                        Phone Number has wrong format</mat-error>
                    <mat-error *ngIf="this.registrationGroup.controls['phoneNumber']?.hasError('duplicate')">
                        {{this.phoneNumberController.value}} is already registered</mat-error>
                </mat-form-field>
                <mat-form-field class="icteam-form-field" appearance="outline">
                    <ngx-mat-intl-tel-input [formControl]='this.parentPhoneNumberController'
                        [onlyCountries]="['eg', 'sa', 'ae', 'qa', 'kw', 'lb', 'ly', 'om', 'dz', 'tn', 'bn', 'es', 'de', 'bh', 'gb', 'tr', 'ke', 'sd', 'rw']"
                        [enablePlaceholder]="true" [enableSearch]="true" name="phone">
                    </ngx-mat-intl-tel-input>
                    <mat-label>Parent Phone Number</mat-label>
                    <mat-icon matSuffix>escalator_warning</mat-icon>
                    <mat-hint>One of your parent's phone numbers</mat-hint>
                    <mat-error *ngIf="this.registrationGroup.controls['parentPhoneNumber']?.hasError('required')">Phone
                        Number is <strong>required</strong></mat-error>
                    <mat-error
                        *ngIf="this.registrationGroup.controls['parentPhoneNumber']?.hasError('validatePhoneNumber')">
                        Phone Number has wrong format</mat-error>
                    <mat-error *ngIf="this.registrationGroup.controls['parentPhoneNumber']?.hasError('matches')">
                        Parent Number must be different thant your own phone number
                    </mat-error>
                </mat-form-field>

            </div>
            <div *ngIf="currentStep == 1" [@fade]>
                <div class="image-button-container">
                    <h5 *ngIf="!this.isEshra7ly">Group Type</h5>
                    <mat-radio-group *ngIf="!this.isEshra7ly" class="icteam-form-field radio-buttons-container"
                        [formControl]='this.groupTypeController' (change)='this.radioButtonChange()'>
                        <mat-radio-button value="school">
                            <ngx-image-button [selected]='this.groupTypeController.value == "school"'
                                src="assets/registration/school-normal.svg"
                                hoverImage="assets/registration/school-hover.svg"
                                selectedImage="assets/registration/school-selected.svg"></ngx-image-button>
                            <p>School Candidate</p>
                        </mat-radio-button>
                        <mat-radio-button value="private">
                            <ngx-image-button [selected]='this.groupTypeController.value =="private"'
                                src="assets/registration/center-normal.svg"
                                hoverImage="assets/registration/center-hover.svg"
                                selectedImage="assets/registration/center-selected.svg"></ngx-image-button>
                            <p>Private Candidate</p>
                        </mat-radio-button>
                    </mat-radio-group>
                    <p *ngIf="!this.groupTypeController.value">
                        <strong>School Candidates</strong>
                        <br>
                        {{environment.org}} is available at your school
                        <br>
                        <br>
                        <strong>Private Candidates</strong>
                        <br>
                        You will register with us <strong>externally</strong>
                    </p>
                    <div class="school-part" [@fade] *ngIf="this.groupTypeController.value == 'school'">
                        <p>We teach at the following schools if you are enrolled at the school <br>Choose your
                            school:</p>
                        <mat-form-field class="icteam-form-field" appearance="outline">
                            <mat-label>School</mat-label>
                            <mat-select [formControl]='this.schoolController' (selectionChange)="this.groupChanged()">
                                <mat-option *ngFor="let school of schoolPlaces" [value]="school.placeName">
                                    {{school.placeName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="this.schoolController.hasError('required')">School is
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="private-part" [@fade] *ngIf="this.groupTypeController.value == 'private'">
                        <p>You can take with us at the following centers or <strong>online</strong></p>
                        <mat-form-field class="icteam-form-field" appearance="outline">
                            <mat-label>Private Group</mat-label>
                            <mat-select [formControl]='this.groupController' (selectionChange)='this.groupChanged()'>
                                <mat-option *ngFor="let group of privatePlaces" [value]="group.placeName">
                                    {{group.placeName}} - {{group.placeArea}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="this.groupController.hasError('required')">Group is
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="icteam-form-field" appearance="outline">
                            <mat-label>School</mat-label>
                            <mat-hint>Write the name of the school you're enrolled at</mat-hint>
                            <input matInput type="text" [formControl]='this.schoolController' />
                            <mat-error *ngIf="this.schoolController.hasError('required')">School is
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="icteam-form-field" appearance="outline"
                            *ngIf="this.groupController?.value?.name == 'Online'">
                            <mat-label>Country of residence</mat-label>
                            <mat-hint>Write your country of residence</mat-hint>
                            <input matInput type="text" [formControl]='this.countryController'
                                [matAutocomplete]="auto" />
                            <mat-error *ngIf="this.countryController.hasError('required')">Country is
                                <strong>required</strong>
                            </mat-error>
                            <mat-autocomplete #auto="matAutocomplete" [displayWith="">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>
                <app-place-info *ngIf="this.chosenPlace != null" [place]="this.chosenPlace"></app-place-info>
            </div>

            <div class="form-buttons">
                <div *ngIf="currentStep == 0"></div>
                <button class="button-width" *ngIf="currentStep > 0" mat-stroked-button color="primary"
                    (click)='back()'>Back</button>
                <button *ngIf="currentStep < 1" style="justify-self: end;" class="button-width" mat-flat-button
                    color="primary" (click)="nextStep()">Next</button>
                <button *ngIf="currentStep == 1" (click)='this.register()' [disabled]='this.registrationGroup.invalid'
                    style="justify-self: end;" class="button-width" mat-flat-button color="primary">Submit</button>
            </div>
        </form>
    </div>
</div>
